import React , {useState} from "react";
import MainTable from "../table";
import style from './Store-tab.module.scss';
import dummy from '../../assets/images/dummyimage.png';
import Modal from '../modal';
import { http } from "../../utilits/http";

const StoreTab = ({setTabOption , tabOption , data , onComplete }) => {
    const [ showModal , setshowModal ] = useState(false);

    const tableField = [ "id" , "store name" , "category" , "mobile" , "location" , "active offer" , "followers count" , "total earnings" ];

    const requestTableFields = [ "id" , "store name" , "category" , "mobile" , "location" , "actions" ];

    const [ storeId , setStoreId ] = useState('');

    const OPTIONS = [
        { label: "Listing", status: "approved" },
        { label: "Request", status: "waiting for approval" },
    ];

    const handleTabSwitch = (item) => {
        setTabOption(item);
    };
    console.log(tabOption , 'optn');
    const handleAccept = () => {
        if (storeId !== null) {
            http.patch(`vendor/approve-store/${storeId}/` , {
                "is_approved": true
            })
            .then((res) => {
                setStoreId(false); 
                setshowModal(false);
                onComplete();
            })
            .catch((err) => {
                console.log(err);
            });
        }
    };

    return(
        <div className={style.tab_wrapper}>
            <div className={style.tab_options}>
                {OPTIONS.map((option, index) => (
                    <div
                        className={`${style.options} ${tabOption === option?.status ? style.active : ''}`}
                        key={index}
                        onClick={() => handleTabSwitch(option?.status)}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
            <div className={style.table_wrapper}>
                {tabOption === "approved" ? (
                    <MainTable headers={tableField} >
                        {data?.map((item, index) => (
                            <tr className="table_body" key={index}>
                                <td className="table_body_data">{item?.id}</td>
                                <td className="table_body_data">
                                    {item?.image ? (
                                        <>
                                            <img src={item?.image} alt={item?.name} style={{ width:'20px' , height:'20px', marginRight:'5px'}}/>
                                            {item?.name}  
                                        </>                                  
                                    ):(
                                        <>
                                        <img src={dummy.src} style={{ width:'20px' , height:'20px', marginRight:'5px'}} />
                                        {item?.name} 
                                        </>
                                    )}
                                </td>
                                <td className="table_body_data">{item?.category}</td>
                                <td className="table_body_data">{item?.mobile}</td>
                                <td className="table_body_data">{item?.location_name}</td>
                                <td className="table_body_data">
                                    <div className={style.active_offer}>
                                        {item?.active_offer}
                                    </div>
                                </td>
                                <td className="table_body_data">{item?.follower_count}</td>
                                <td className="table_body_amount">₹{item?.total_earnings}</td>
                            </tr>
                        ))}
                    </MainTable>
                ) : (
                    <MainTable headers={requestTableFields} >
                        {data?.map((item, index) => (
                            <tr className="table_body" key={index}>
                            <td className="table_body_data">{item?.id}</td>
                            <td className="table_body_data">
                                {item?.image ? (
                                    <>
                                        <img src={item?.image} alt={item?.name} style={{ width:'20px' , height:'20px', marginRight:'5px'}}/>
                                        {item?.name}  
                                    </>                                  
                                ):(
                                    <>
                                    <img src={dummy.src} style={{ width:'20px' , height:'20px', marginRight:'5px'}} />
                                    {item?.name} 
                                    </>
                                )}
                            </td>
                            <td className="table_body_data">{item?.category}</td>
                            <td className="table_body_data">{item?.mobile}</td>
                            <td className="table_body_data">{item?.location_name}</td>
                            <td>
                                {item?.status === "waiting for approval" ? (
                                    <button 
                                        type="button" 
                                        onClick={() => { setStoreId(item.id); setshowModal(true); }}
                                        className={style.btn_accept}
                                    >
                                        Accept
                                    </button>
                                ):(
                                    <div className={style.status_approve}>Approved</div>
                                )}
                            </td>
                        </tr>
                        ))}
                    </MainTable>
                )}
                {showModal && (
                    <div className={style.modal_section}>
                        <Modal onClose={() => { setshowModal(false) }}>
                            <div className={style.heading}>
                                Request
                            </div>
                            <div className={style.content}>
                                Are you sure you want to onboard the store?
                            </div>
                            <div className={style.modal_btns}>
                                <button type="button" className={style.btn_yes} onClick={handleAccept}>Yes</button>
                                <button type="button" className={style.btn_no} onClick={() => { setshowModal(false) }}>No</button>
                            </div>
                        </Modal>
                    </div>
                )}
            </div>
        </div>
    )
}
export default StoreTab;